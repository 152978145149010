import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"

import css from "./email.module.scss"

const metadata = {
  title: "ReferralCandy email address verification",
  description: "Successfully verified your email address",
}

// Prevent flash of 404 during page redirect for client-only paths created in gatsby-node
// See: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119
const browser = typeof window !== "undefined" && window

const SuccessfullyVerifiedEmail = (props) =>
  browser && (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container}>
        <h2>Your email address is successfully verified</h2>
        <h3> We will send out email under your email address later</h3>
      </Grid>
    </Layout>
  )

export default SuccessfullyVerifiedEmail
